import $ from 'jquery';
import Component from 'Component';
import Macy from 'macy';

class SocialMedia extends Component {

    $socialMedia;      

    constructor(selector) {            
        super(selector);

        this.init(() => {
            const { $element } = this;
            
            this.$socialMedia = $element;
            const $socialMediaChildren = $('.social-media__grid-item');
            const $socialMediaLoadMore = $('.js-sm-load-more');
            const $socialMediaModal = $('.js-sm-modal');
            const $socialMediaModalClose = $('.js-sm-modal-close');
            let postsLoaded = $socialMediaChildren.length;

            if (this.$socialMedia && this.$socialMedia.length) {
                $(() => {
                  // Masonry layout settings
                  let macyInstance = Macy({
                    container: '.social-media__grid',
                    columns: 2,
                    margin: {
                      x: 18,
                      y: 18  
                    },
                    mobileFirst: true,
                    trueOrder: true,
                    breakAt: {
                      769: {
                        columns: 3
                      },
                      1024: {
                        columns: 4
                      }
                    }
                  });

                  function createModalIframe(url, item) {
                    if(url.indexOf('facebook.com/plugins/post') >= 0) {
                      var iframe = document.createElement("iframe");
                      iframe.setAttribute("src", url);
                      iframe.style.width = "552px";
                      iframe.style.height = "613px";
                      iframe.style.backgroundColor = "white";
                      $socialMediaModal.find('.social-media__modal-inner').append(iframe);
                      $socialMediaModal.addClass('modal-type--facebook');
                    } else if(url.indexOf('facebook.com/plugins/video') >= 0) {
                      var iframe = document.createElement("iframe");
                      iframe.setAttribute("src", url);
                      if(item.data('layout') == 'portrait') {
                        iframe.style.height = "534px";
                        iframe.style.width = "300px";
                      } else {
                        iframe.style.height = "387px";
                        iframe.style.width = "688px";
                      }
                      $socialMediaModal.find('.social-media__modal-inner').append(iframe);
                      $socialMediaModal.addClass('modal-type--facebook-video');
                    } else if(url.indexOf('instagram') >= 0) {
                      $(`.instagram-embed[data-instagram-embed-id="${item.data('instagram-embed')}"]`).appendTo($socialMediaModal.find('.social-media__modal-inner'));
                      $socialMediaModal.addClass('modal-type--instagram');
                    }
                  }

                  function destroyModalIframe() {
                    if($socialMediaModal.is('.modal-type--facebook, .modal-type--facebook-video')) {
                      $socialMediaModal.find('iframe').remove();
                    } else if($socialMediaModal.hasClass('modal-type--instagram')) {
                      $socialMediaModal.find('.instagram-embed').appendTo('.instagram-embeds');
                    }
                    $socialMediaModal.removeClass('modal-type--facebook modal-type--facebook-video modal-type--instagram');
                  };

                  // Click events for each link
                  $socialMediaChildren.each(function() {
                    $(this).on('click', function(e){
                      if($(this).data('modal') !== true || window.innerWidth < 769) return

                      let embedUrl = $(this).data('embed');
                      if(!embedUrl) return

                      e.preventDefault();
                      $socialMediaModal.addClass('open');
                      createModalIframe(embedUrl, $(this));
                    });
                  });

                  // Modal open/close
                  $socialMediaModalClose.each(function() {
                    $(this).on('click', function(e){
                      e.preventDefault();
                      $socialMediaModal.removeClass('open');
                      destroyModalIframe();
                    });
                  });

                  // Load more button functionality
                  let postsPerLoad = 4;
                  let postsCurrentlyDisplayed = postsPerLoad;

                  if(postsLoaded > postsPerLoad) {
                    $socialMediaLoadMore.on('click', (e) => {
                      e.preventDefault();
                      
                      // Load next set of posts
                      let $nextPosts = $socialMediaChildren.slice(postsCurrentlyDisplayed, postsCurrentlyDisplayed + postsPerLoad);
                      $nextPosts.show();
                      postsCurrentlyDisplayed += postsPerLoad;

                      // Recalculate the layout
                      setTimeout(() => {
                        macyInstance.recalculate();
                      }, 150);

                      $socialMediaLoadMore.hide();
                    });
                  } else {
                    $socialMediaLoadMore.remove();
                  }
                });
            }
        });
    }
}

export default SocialMedia;
