import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';
import utils from 'utilities';
import App from 'App';
import CarouselComponent from 'Carousel';

class OffersCarousel extends Component {
    constructor(selector) {
        super(selector);
        this.$carouselContainer = $(selector);
        this.$carousel = this.$carouselContainer.find('.c-carousel-slides');
        this.$controls = this.$carouselContainer.find('.c-carousel__controls');
        this.$indicators = this.$carouselContainer.find('.c-carousel-indicators');
        this.initSlickCarousel();
        this.handleCarouselUpdates();
        this.checkSlideCount();

        this.init(() => {
            const { $element } = this;
            this.anchorId = $element.attr('id');
            this.addEventHandler('scrolledIntoView',
                () => {
                    if (utils.device.isLarge()) {
                        App.setSidebarState('collapsed');
                    } else {
                        App.setSidebarState('secondary');
                    }

                    App.setSecondaryNavState(this.anchorId);
                })
        })
    }

    checkSlideCount() {
        this.slideCount = this.$carousel.slick('getSlick').slideCount;
        const isMobile = window.innerWidth <= 769;
        const indicators = $('.c-carousel-indicators');
        const showControls = isMobile ? this.slideCount > 3 : this.slideCount <= 3;

        if(isMobile) {
            this.$controls.hide();
            this.slideCount > 1 && indicators.show();
        } else {
            this.slideCount < 3 ? indicators.hide() : indicators.show();
            this.slideCount < 3 ? this.$controls.hide() : this.$controls.show();
        }
    }

    initSlickCarousel() {
        const indicatorContainer = $('<div class="c-carousel-indicators grid-container"></div>');
        this.$carouselContainer.append(indicatorContainer);
        this.$carousel.slick({
            infinite: false,
            variableWidth: true,
            arrows: true,
            prevArrow: this.$carouselContainer.find('.c-carousel__back-button'),
            nextArrow: this.$carouselContainer.find('.c-carousel__next-button'),
            dots: true,
            appendDots: indicatorContainer,
            mobileFirst: true,
            slidesToScroll: 1
        });

        this.$carousel.on('init reInit afterChange', () => {
            this.checkSlideCount();
            this.updateLinkTabIndices(); 
            this.updateLinkHref();
        });

        this.updateLinkTabIndices();
        this.updateLinkHref();

        $(window).on('resize', this.checkSlideCount.bind(this));
    }

    handleCarouselUpdates() {
        this.$carousel.find('.slick-slide').attr('aria-hidden', false);
        this.checkSlideCount();
        this.updateLinkTabIndices();
        this.updateLinkHref();
    }

    updateLinkTabIndices() {
        this.$carousel.find('.slick-slide a').attr('tabindex', '0');
    }

    updateLinkHref() {
        this.$carousel.find('.slick-slide a').each(function() {
            const $link = $(this);
            const redirectUrl = $link.data('goal-endpoint-redirect');
            if (redirectUrl && !$link.attr('href')) {
                $link.attr('href', redirectUrl);
            }
        });
    }
}

export default OffersCarousel;