import Component from 'Component';
import $ from 'jquery';
import utils from 'utilities';

class PlotStickyNav {
    constructor($element) {
        if (!$element || $element.length === 0) {
            throw new Error('Element not found or not passed to the constructor.');
        }

        this.$element = $element;

        let infoBar = $('.sticky-infomation-bar');

        if (infoBar.length > 0 && infoBar.children().length > 0) {
            this.$element.addClass('info-bar-present');
        }

        // Attach smooth scroll listener and initialize carousel
        this.attachSmoothScrollListener();
        this.initSlickCarousels();

        // Update header height and scroll margin, and bind resize event
        this.updateHeaderHeight();
        this.updateScrollMargin();
        this.bindResizeEvent();

        // Initialize variables from feature/project-backlog
        const plotHeader = $('.plot-header');
        const mobileStickyNav = $('.c-development-sticky-cta');

        mobileStickyNav.hide();

        if (plotHeader.length > 0) {
            let topOfPlotHeader = plotHeader.offset().top + 500;

            $(window).on('scroll', () => {
                if ($(window).scrollTop() > topOfPlotHeader && $('html').width() <= 1023) {
                    if (!mobileStickyNav.is(':visible')) {
                        mobileStickyNav.slideDown();
                    }
                } else {
                    if (mobileStickyNav.is(':visible')) {
                        mobileStickyNav.slideUp();
                    }
                }
            });
        } else {
            $(window).on('scroll', () => {
                if ($(window).scrollTop() > 600 && $('html').width() <= 1023) {
                    if (!mobileStickyNav.is(':visible')) {
                        mobileStickyNav.slideDown();
                    }
                } else {
                    if (mobileStickyNav.is(':visible')) {
                        mobileStickyNav.slideUp();
                    }
                }
            });
        }
    }

    updateHeaderHeight() {
        const stickyHeader = document.querySelector('.plot-sticky-nav');
        this.headerHeight = stickyHeader ? stickyHeader.offsetHeight : 0;
    }

    updateScrollMargin() {
        const extraPadding = 0;
        const stickyHeaderHeight = this.headerHeight;

        document.querySelectorAll('[id]').forEach(target => {
            target.style.scrollMarginTop = `${stickyHeaderHeight + extraPadding}px`;
        });
    }

    handleAnchorClick(e) {
        e.preventDefault();
        const link = e.delegateTarget;
        const anchorTargetId = link.getAttribute('href');
        const anchorTarget = $(anchorTargetId);

        anchorTarget[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    attachSmoothScrollListener() {
        this.$element.find('.c-secondary-nav__anchor-link[href^="#"]').on('click', (e) => this.handleAnchorClick(e));
    }

    detachSmoothScrollListener() {
        this.$element.find('.c-secondary-nav__anchor-link[href^="#"]').off('click', (e) => this.handleAnchorClick(e));
    }

    bindResizeEvent() {
        $(window).on('resize', () => {
            this.updateHeaderHeight();
            this.updateScrollMargin();
        });
    }

    initSlickCarousels() {
        $(".plot-sticky-nav__carousel").not('.slick-initialized').slick({
            infinite: false,
            variableWidth: true,
            arrows: true,
            touchMove: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: "unslick"
                }
            ]
        });
    }
}

export default PlotStickyNav;
